@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Gilroy, system-ui, sans-serif;
  }

  select {
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }
}

@layer components {
  html {
    @apply text-xs xs:text-base md:text-sm lg:text-base 3xl:text-2xl
  }

  body {
    width: 100%;
  }

  .btn__outlined {
    @apply relative py-[1.25rem] outlined_gradient;
  }

  .btn__outlined::before {
    @apply rounded-2xl;
  }

  .outlined_gradient {
    position: relative;
  }

  .outlined_gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    background: linear-gradient(180deg, #F79F2D 0%, #E44E98 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    /*-webkit-mask-composite: destination-out;*/
    mask-composite: exclude;
    pointer-events: none;
  }

  .bg-gradient {
    @apply bg-gradient-to-r from-[#FF4D4D] to-[#FF971D]
  }

  .text-gradient {
    background: linear-gradient(180deg, #F79F2D 0%, #FF4D4D 100%);
    -webkit-background-clip: text;
    color: transparent;
  }

  .blurred {
    @apply backdrop-blur-xl bg-neutral-900/[0.37]
  }

  .scroll-hidden {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .scroll-hidden::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .file-selector-hidden::file-selector-button {
    display: none;
  }

  .input-error {
    @apply !border-red-400 animate-errorShake
  }

  .checkbox-error {
    @apply before:!border-red-400 animate-errorShake
  }

  .datepicker {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }

  .datepicker::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .admin_item_hidden {
    @apply grayscale opacity-50
  }

  .skeleton {
    @apply opacity-70 will-change-auto animate-skeletonLoading
  }

  .skeleton-text {
    @apply h-2 w-full rounded-sm
  }

  .skeleton-text:last-child {
    @apply w-9/12
  }
}

.captcha-visible .grecaptcha-badge {
  visibility: visible !important;
  z-index: 1;
}

body .grecaptcha-badge {
  visibility: hidden;
}
